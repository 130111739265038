import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';

gsap.registerPlugin(Flip);

// Function to handle the initial state setup for elements
export function setInitialStates() {
  const introTitle = [...document.querySelectorAll('.intro-title span span')];
  const preloaderText = document.querySelector('.preloader__text span');

  // Log elements to see if they are selected correctly
  console.log('Intro Titles:', introTitle);
  console.log('Preloader Text:', preloaderText);

  // Set initial states for elements
  gsap.set([preloaderText, introTitle], {
    yPercent: 100,
    autoAlpha: 0,
  });
}

// Function to handle preloader animation
export function preloaderAnimation() {
  const preloaderBackground = document.querySelector('.preloader__background');
  const preloaderText = document.querySelector('.preloader__text span');

  // Log elements to see if they are selected correctly
  console.log('Preloader Background:', preloaderBackground);
  console.log('Preloader Text:', preloaderText);

  const tl = gsap.timeline({
    defaults: {
      ease: 'power2.out',
    },
  });

  tl.to(preloaderText, {
    yPercent: 0,
    delay: 0.3,
  })
    .to(preloaderText, {
      yPercent: -105,
      delay: 1,
    })
    .to(preloaderBackground, {
      yPercent: -100,
      duration: 1.5,
      ease: 'power4.inOut',
    });

  return tl;
}

// Master timeline to control all animations
export function masterAnimation() {
  const tl = gsap.timeline();

  // Set initial states
  tl.add(setInitialStates());

  // Preloader Animation
  tl.add(preloaderAnimation());

  return tl;
}
