import { gsap } from 'gsap';
import { masterAnimation, setInitialStates } from './animations';

// Create a master timeline to control all animations
document.addEventListener("DOMContentLoaded", function () {
  // Set initial states for elements after DOM is fully loaded
  setInitialStates();

  // Run the master animation
  masterAnimation();
});
